import Attribute from './attributes/base';
import Color from './attributes/color';
import Opacity from './attributes/opacity';
import Position from './attributes/position';
import Shape from './attributes/shape';
import Size from './attributes/size';
import { getAttribute, registerAttribute } from './factory';
registerAttribute('Color', Color);
registerAttribute('Opacity', Opacity);
registerAttribute('Position', Position);
registerAttribute('Shape', Shape);
registerAttribute('Size', Size);
export { registerAttribute, getAttribute, Attribute, 
// 以下 export 是为了兼容，理论上是不需要的
Color, Opacity, Position, Shape, Size, };
export * from './interface';
